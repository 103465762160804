import { AwsConfigs, DatabricksConfig, Environment } from './environment.model';
import { REGION } from './shared/region';

export const AWS_CONFIGS_DEV: AwsConfigs = {
  default: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_qv9Q88I5O',
    aws_user_pools_web_client_id: '68rcr4t69ee1f6ujpkpbm87etq',
  },
  daimlerbus: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_vk4W1ZLcD',
    aws_user_pools_web_client_id: '11qvtrbuuachueepi4e1nm5cbq',
    federatedSignIn: {
      identityProvider: 'TWAICEAzureAD-DEV',
      domain: 'daimlerbus-auth.dev.twaice.cloud',
      allowedHost: 'daimlerbus.dev.twaice.cloud',
      redirectUrl: 'https://daimlerbus.dev.twaice.cloud',
    },
  },
  internal_sso: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_fC2G5t1b1',
    aws_user_pools_web_client_id: '2j2gvnab8fh3h464t7902fqmpn',
    federatedSignIn: {
      identityProvider: 'Microsoft365EntraIdProvider',
      domain: 'internal-auth.dev.twaice.cloud',
      // allowedHost: 'localhost:4200', // Enable when using for local debugging
      // redirectUrl: 'localhost:4200', // Enable when using for local debugging
      allowedHost: 'internal.dev.twaice.cloud', // Disable when using for local debugging
      redirectUrl: 'https://internal.dev.twaice.cloud', // Disable when using for local debugging
    },
  },
};

export const DATABRICKS_CONFIG_DEV: DatabricksConfig = {
  tokenArn: '/twaice/databricks/workspaces/dev_fe_token',
  pathArn: '/twaice/databricks/workspaces/dev/warehouses/dev-backend-sql-warehouse/http_path',
  hostArn: '/twaice/databricks/workspaces/dev/warehouses/dev-backend-sql-warehouse/server_hostname',
  databaseNames: {
    measurements: 'dev_dl_measurements',
    battery_kpis: 'dev_dl_battery_kpis',
    customer_config: 'dev_dl_customer_config',
    performance_manager: 'dev_dl_performance_manager',
  },
};

export const ENVIRONMENT_DEV: Environment = {
  production: false,
  name: process.env['ENV_NAME'] || 'dev',
  staticFilesHost: 'https://static.dev.twaice.ai/',
  baseUrlBe4Fe: 'https://be4fe.dev.twaice.ai/',
  baseUrlApi: 'https://api.dev.twaice.ai/',
  awsPinpoint: {
    appId: '276bbc09e2c04c0dbce30c7c8590a87c',
    region: REGION,
    mandatorySignIn: true,
  },
  /* eslint-enable @typescript-eslint/naming-convention */
  // public identifier for the Transifex project
  transifexToken: '1/e60edf51d3cbc5c2a847caef792f9da32ba18ef6',
  // public Hotjar site ID
  hotjarSiteId: '3508798',
  // public Intercom App ID
  intercomAppId: 'thagvi83',
  // public Mixpanel ID
  mixpanelId: '4f213a56d72f86ef826d5cc8b1ed19e9',
  identityPoolAuth: {
    identityPoolId: 'eu-west-1:a77ab16c-cd75-499b-ba05-c8d364a6c3ce',
    region: REGION,
    identityPoolRegion: REGION,
  },
};
