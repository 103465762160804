import { StackedBarInfo } from '@twaice-fe/frontend/shared/utilities';
import { PerformanceManagerKPI } from '@twaice-fe/shared/models';

export enum BalancingSeverityEnum {
  CRITICAL = 'high',
  ELEVATED = 'medium',
  NORMAL = 'balanced',
  MISSING = 'missing',
}

export enum TemperatureSeverityEnum {
  CRITICAL = 'critical',
  ELEVATED = 'elevated',
  NORMAL = 'optimal',
  LOW = 'low',
  MISSING = 'missing',
}

export const balancingStackedBarInfo: StackedBarInfo = {
  [BalancingSeverityEnum.CRITICAL]: {
    color: '#FF0000',  // rgb(255,0,0)
    label: 'High imbalances',
    thresholdText: '-',
  },
  [BalancingSeverityEnum.ELEVATED]: {
    color: '#F0E442',  // rgb(240, 228, 66)
    label: 'Medium imbalances',
    thresholdText: '-',
  },
  [BalancingSeverityEnum.NORMAL]: {
    color: '#009E73',  // rgb(0,158,115)
    label: 'Balanced',
    thresholdText: '-',
  },
  [BalancingSeverityEnum.MISSING]: { 
    color: '#0000000a',  // rgba(0, 0, 0, 0.04)
    label: 'Missing data' 
  },
};


export interface KPIInfoInterface {
  [key: string]: {
    label: string;
    unit?: string;
    visualisationThresholds: KPIVisualisationThresholds;
    visualMapPrecision?: number;
  };
}

export interface KPIVisualisationThresholds {
  [BalancingSeverityEnum.CRITICAL]?: number;
  [BalancingSeverityEnum.ELEVATED]?: number;
  [BalancingSeverityEnum.NORMAL]?: number;
  [BalancingSeverityEnum.MISSING]?: number;
  [TemperatureSeverityEnum.CRITICAL]?: number;
  [TemperatureSeverityEnum.ELEVATED]?: number;
  [TemperatureSeverityEnum.NORMAL]?: number;
  [TemperatureSeverityEnum.LOW]?: number;
  [TemperatureSeverityEnum.MISSING]?: number;
}

export const kpiInfo: KPIInfoInterface = {
  [PerformanceManagerKPI.VOLTAGE_SPREAD]: {
    label: 'Voltage spread',
    unit: 'V',
    visualisationThresholds: {
      [BalancingSeverityEnum.CRITICAL]: 0.2,
      [BalancingSeverityEnum.ELEVATED]: 0.05,
      [BalancingSeverityEnum.NORMAL]: 0,
      [BalancingSeverityEnum.MISSING]: 0,
    },
    visualMapPrecision: 2,
  },
  [PerformanceManagerKPI.SOC_SPREAD]: {
    label: 'SoC spread',
    unit: '%',
    visualisationThresholds: {
      [BalancingSeverityEnum.CRITICAL]: 100,
      [BalancingSeverityEnum.ELEVATED]: 15,
      [BalancingSeverityEnum.NORMAL]: 0,
      [BalancingSeverityEnum.MISSING]: 0,
    },
  },
  [PerformanceManagerKPI.TEMPERATURE_MAX]: {
    label: 'Temperature max',
    unit: '°C',
    visualisationThresholds: {
      [TemperatureSeverityEnum.CRITICAL]: 50,
      [TemperatureSeverityEnum.ELEVATED]: 33,
      [TemperatureSeverityEnum.NORMAL]: 25,
      [TemperatureSeverityEnum.LOW]: 0,
      [TemperatureSeverityEnum.MISSING]: 0,
    },
  },
  [PerformanceManagerKPI.TEMPERATURE_SPREAD]: {
    label: 'Temperature spread',
    unit: '°C',
    visualisationThresholds: {
      [TemperatureSeverityEnum.CRITICAL]: 15,
      [TemperatureSeverityEnum.ELEVATED]: 8,
      [TemperatureSeverityEnum.NORMAL]: 0,
      [TemperatureSeverityEnum.MISSING]: 0,
    },
  },
  [PerformanceManagerKPI.TEMPERATURE_MEAN]: {
    label: 'Temperature mean',
    unit: '°C',
    visualisationThresholds: {
      [TemperatureSeverityEnum.CRITICAL]: 45,
      [TemperatureSeverityEnum.ELEVATED]: 30,
      [TemperatureSeverityEnum.NORMAL]: 30,
      [TemperatureSeverityEnum.LOW]: 0,
      [TemperatureSeverityEnum.MISSING]: 0,
    },
  },
};

export const temperatureStackedBarInfo: StackedBarInfo = {
  [TemperatureSeverityEnum.CRITICAL]: {
    color: '#FF0000',
    label: 'Critical module temperatures',
    thresholdText: '-',
  },
  [TemperatureSeverityEnum.ELEVATED]: {
    color: '#F0E442',
    label: 'Elevated module temperatures',
    thresholdText: '-',
  },
  [TemperatureSeverityEnum.NORMAL]: {
    color: '#009E73',
    label: 'Optimal module temperatures',
    thresholdText: '-',
  },
  [TemperatureSeverityEnum.MISSING]: { color: '#0000000A', label: 'Missing data' },
};
