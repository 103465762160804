import { AwsConfigs, DatabricksConfig, Environment } from './environment.model';
import { REGION } from './shared/region';

export const AWS_CONFIGS_PROD: AwsConfigs = {
  default: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_MbpMN2XZW',
    aws_user_pools_web_client_id: '1ra1hfmu6fpi8iqcmje8hoap4c',
  },
  daimlerbus: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_YBezyPGAj',
    aws_user_pools_web_client_id: '2ctpq6mmnva00qgp7ic4irfk8c',
    federatedSignIn: {
      identityProvider: 'DaimlerbusOIDCProvider',
      domain: 'daimlerbus-auth.twaice.cloud',
      allowedHost: 'daimlerbus.twaice.cloud',
      redirectUrl: 'https://daimlerbus.twaice.cloud',
    },
  },
  internal_sso: {
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_POVy8AdqQ',
    aws_user_pools_web_client_id: '21h86v60sdh9m82j3t64nlv3qa',
    federatedSignIn: {
      identityProvider: 'Microsoft365EntraIdProvider',
      domain: 'internal-auth.twaice.cloud',
      allowedHost: 'internal.twaice.cloud',
      redirectUrl: 'https://internal.twaice.cloud',
    },
  },
};

export const DATABRICKS_CONFIG_PROD: DatabricksConfig = {
  tokenArn: process.env['DATABRICKS_TOKEN_ARN'] || '/twaice/databricks/workspaces/prod_fe_token',
  pathArn:
    process.env['DATABRICKS_PATH_ARN'] || '/twaice/databricks/workspaces/prod/warehouses/prod-backend-sql-warehouse/http_path',
  hostArn:
    process.env['DATABRICKS_HOST_ARN'] ||
    '/twaice/databricks/workspaces/prod/warehouses/prod-backend-sql-warehouse/server_hostname',
  databaseNames: {
    measurements: process.env['DATABRICKS_MEASUREMENTS_DB_NAME_ARN'] || 'prod_dl_measurements',
    battery_kpis: process.env['DATABRICKS_BATTERY_KPIS_DB_NAME_ARN'] || 'prod_dl_battery_kpis',
    customer_config: process.env['DATABRICKS_CUSTOMER_CONFIG_DB_NAME_ARN'] || 'prod_dl_customer_config',
    performance_manager: process.env['DATABRICKS_PERFORMANCE_MANAGER_DB_NAME_ARN'] || 'prod_dl_performance_manager',
  },
};

export const ENVIRONMENT_PROD: Environment = {
  production: true,
  name: process.env['ENV_NAME'] || 'prod',
  staticFilesHost: 'https://static.twaice.ai/',
  baseUrlBe4Fe: 'https://be4fe.twaice.ai/',
  baseUrlApi: 'https://api.twaice.ai/',
  awsPinpoint: {
    appId: '230c1a0de02e439e8bc2c22dea22f964',
    region: REGION,
    mandatorySignIn: true,
  },
  /* eslint-enable @typescript-eslint/naming-convention */

  // public identifier for the Transifex project
  transifexToken: '1/e60edf51d3cbc5c2a847caef792f9da32ba18ef6',
  // public Hotjar site ID
  hotjarSiteId: '3451095',
  // public Intercom App ID
  intercomAppId: 'thagvi83',
  // public Mixpanel ID
  mixpanelId: 'e98640b38fc715de1089148141a0513e',
  identityPoolAuth: {
    identityPoolId: 'eu-west-1:5d31c643-d324-418a-99e9-9567a95871fc',
    region: REGION,
    identityPoolRegion: REGION,
  },
};
