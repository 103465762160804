import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BasePerformanceMangerParams,
  PerformanceManagerResponseInterface,
  PerformanceManagerKPI,
  StringKPIInterface,
  StringKPIParams,
  SystemInsightsInterface,
  SystemInsightsParams,
  SystemKPIInterface,
  SystemKPIParams,
  SystemSeverityLevelResultInterface,
  SystemStatisticsInterface,
  SystemStatisticsParams,
  DataNotFoundError,
  EnergyLossDataInterface,
} from '@twaice-fe/shared/models';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PerformanceManagerService {
  constructor(private httpClient: HttpClient) {}

  fetchSystemStatistics(params: SystemStatisticsParams): Observable<SystemStatisticsInterface> {
    const url = this.buildUrl('performance-manager/statistics', [
      params.customerBk,
      params.systemBk,
      params.levelBk,
      params.kpi,
    ]);

    const httpParams = new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate);

    return this.fetchData<SystemStatisticsInterface>(url, httpParams);
  }

  fetchSystemKPIData(params: SystemKPIParams): Observable<SystemKPIInterface> {
    const url = this.buildUrl('performance-manager/data', [
      params.customerBk,
      params.systemBk,
      params.levelBk,
      params.kpi,
    ]);

    const httpParams = new HttpParams().set('date', params.date);

    return this.fetchData<SystemKPIInterface>(url, httpParams);
  }

  fetchSystemInsights(params: SystemInsightsParams): Observable<SystemInsightsInterface> {
    const insightType = [PerformanceManagerKPI.SOC_SPREAD, PerformanceManagerKPI.VOLTAGE_SPREAD].includes(params.kpi)
      ? 'imbalance'
      : 'thermal';

    const url = this.buildUrl(`performance-manager/insights/${insightType}`, [
      params.customerBk,
      params.systemBk,
      params.levelBk,
      params.kpi,
      params.date,
    ]);

    return this.httpClient.get<PerformanceManagerResponseInterface<SystemInsightsInterface>>(url).pipe(
      map(({ data }) => data)
    );
  }

  fetchSystemSeverityLevel(params: BasePerformanceMangerParams): Observable<SystemSeverityLevelResultInterface> {
    const url = this.buildUrl('performance-manager/severity', [params.customerBk, params.systemBk]);
    return this.httpClient.get<PerformanceManagerResponseInterface<SystemSeverityLevelResultInterface>>(url).pipe(
      map(({data}) => data)
    );
  }

  fetchStringKPIData(params: StringKPIParams): Observable<StringKPIInterface> {
    const url = this.buildUrl('performance-manager/data', [
      params.customerBk,
      params.systemBk,
      params.levelBk,
      params.inverterBk,
      params.kpi,
    ]);

    const httpParams = new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate);

    return this.fetchData<StringKPIInterface>(url, httpParams);
  }

  fetchEnergyLossData(): Observable<PerformanceManagerResponseInterface<EnergyLossDataInterface[]>> {
    const url = this.buildUrl("performance-manager/latest-energy-loss", [])
    return this.fetchData<PerformanceManagerResponseInterface<EnergyLossDataInterface[]>>(url);
  }

  private fetchData<T>(url: string, params?: HttpParams): Observable<T> {
    return this.httpClient.get<PerformanceManagerResponseInterface<T>>(url, { params }).pipe(
      map(({ data }) => {
        if (!(data as any)?.data?.length) {
          throw new DataNotFoundError(`No data found for the given parameters.`);
        }
        return data;
      }), 
      catchError((error: Error) => {
        console.error(`Error occurred while fetching data from ${url} with params: ${params?.toString()}`, error.message);
        return []
      })
    );
  }

  private buildUrl(endpoint: string, params: (string | number)[]): string {
    return `${endpoint}/${params.join('/')}`;
  }

  private getTableNameBasedOnUsedKPI(kpi: PerformanceManagerKPI): string {
    return kpi === PerformanceManagerKPI.SOC_SPREAD || kpi === PerformanceManagerKPI.VOLTAGE_SPREAD ? 'balancing' : 'thermal';
  }
}
