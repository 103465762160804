import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService, SystemsService } from '@twaice-fe/frontend/shared/services';
import {
  ANALYTICS_UPSELL_ROUTE,
  ENERGY_BASE_ROUTE,
  ENERGY_INCIDENTS_ROUTE,
  ENERGY_PERFORMANCE_MANAGER_ROUTE,
  ENERGY_PREDICTION_ROUTE,
  ENERGY_WARRANTY_ROUTE,
  MOBILITY_BASE_ROUTE,
  REPORTING_ROUTE,
} from '@twaice-fe/shared/constants';
import { CustomerType, User } from '@twaice-fe/shared/models';
import { configsSelectors } from 'libs/frontend/shared/store/src/selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'twaice-fe-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  loggedInUser$: Observable<User>;
  username: string;

  title: string;
  showAnalyticsUpsell = false;
  intercomTarget = 'dashboard-layout-toolbar';

  constructor(
    private destroy$: DestroyRef,
    private authService: AuthService,
    private systemsService: SystemsService,
    private router: Router,
    protected store: Store
  ) {
    this.loggedInUser$ = this.authService.getUserObservable();
    this.username = this.authService.getUserFullName();

    this.store
      .select(configsSelectors.getCustomerType)
      .pipe(takeUntilDestroyed())
      .subscribe((customerType) => {
        switch (customerType) {
          case CustomerType.ENERGY:
            this.title = 'Energy Analytics';
            return;
          case CustomerType.MOBILITY:
            this.title = 'E-mobility Analytics Suite';
            return;
          case CustomerType.MODELS:
            this.title = 'Analytics Suite';
            this.showAnalyticsUpsell = true;
            return;
        }
      });
  }

  /**
   * Logs the user out, resets configuration, and navigates to the login page.
   */
  async logout() {
    this.systemsService.resetConfig();
    await this.authService.logout();
  }

  navigateToBaseUrl() {
    this.store
      .select(configsSelectors.getBaseUrl)
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe((baseUrl) =>
        this.router.navigate([this.showAnalyticsUpsell ? '/analytics' : baseUrl], { queryParamsHandling: 'preserve' })
      );
  }

  openHelpCenter(): void {
    window.open('https://help.twaice.cloud', '_blank');
  }

  baseUrlActive() {
    return (
      this.router.url.includes(ENERGY_BASE_ROUTE) ||
      this.router.url.includes(ENERGY_PERFORMANCE_MANAGER_ROUTE) ||
      this.router.url.includes(ENERGY_PREDICTION_ROUTE) ||
      this.router.url.includes(ENERGY_WARRANTY_ROUTE) ||
      this.router.url.includes(ENERGY_INCIDENTS_ROUTE) ||
      this.router.url.includes(REPORTING_ROUTE) ||
      this.router.url.includes(ANALYTICS_UPSELL_ROUTE) ||
      this.router.url.includes(MOBILITY_BASE_ROUTE)
    );
  }
}
