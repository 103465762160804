import { Routes } from '@angular/router';
import { hasSolution, redirectToSolutionLanding } from '@twaice-fe/frontend/shared/services';
import { Solution } from '@twaice-fe/shared/models';
import { AnalyticsUpsellComponent } from './views/analytics-upsell/analytics-upsell.component';
import { SolutionComponent } from './views/solution/solution.component';
import { SubPageComponent } from './views/sub-page/sub-page.component';

export const solutionRoutes: Routes = [
  {
    path: '',
    canActivate: [redirectToSolutionLanding],
    component: SolutionComponent,
  },
  {
    path: 'solutions',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: 'energy',
    component: SolutionComponent,
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        component: SubPageComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@twaice-fe/frontend/features/energy-analytics/portfolio-overview').then(
                (m) => m['EnergyPortfolioOverviewModule']
              ),
          },
        ],
      },
      {
        path: 'health',
        component: SubPageComponent,
        canActivate: [hasSolution(Solution.ENERGY)],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@twaice-fe/frontend/features/energy-analytics/health').then((m) => m['EnergyHealthModule']),
          },
        ],
      },
      {
        path: 'warranty',
        component: SubPageComponent,
        canActivate: [hasSolution(Solution.ENERGY)],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@twaice-fe/frontend/features/energy-analytics/warranty-tracker').then((m) => m['WarrantyTrackerModule']),
          },
        ],
      },
    ],
  },
  {
    path: 'health-prediction',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.ENERGY)],
    children: [
      {
        path: '',
        redirectTo: 'health-prediction',
        pathMatch: 'full',
      },
      {
        path: 'health-prediction',
        component: SubPageComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('@twaice-fe/frontend/features/energy-prediction').then((m) => m['EnergyPredictionModule']),
          },
        ],
      },
    ],
  },
  {
    path: 'storage-monitoring',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.ENERGY)],
    children: [
      {
        path: '',
        redirectTo: 'data-explorer',
        pathMatch: 'full',
      },
      {
        path: 'data-explorer',
        component: SubPageComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@twaice-fe/frontend/features/storage-monitoring/data-explorer').then((m) => m['DataExplorerModule']),
          },
        ],
      },
    ],
  },
  {
    path: 'reporting',
    component: SolutionComponent,
    children: [
      {
        path: '',
        redirectTo: 'library',
        pathMatch: 'full',
      },
      {
        path: 'library',
        component: SubPageComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('@twaice-fe/frontend/features/reporting').then((m) => m['ReportingModule']),
          },
        ],
      },
    ],
  },
  {
    path: 'on-demand-services',
    component: SolutionComponent,
    children: [
      {
        path: '',
        redirectTo: 'library',
        pathMatch: 'full',
      },
      {
        path: 'library',
        component: SubPageComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('@twaice-fe/frontend/features/reporting').then((m) => m['ReportingModule']),
          },
        ],
      },
    ],
  },
  {
    path: 'incidents',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.ENERGY)],
    children: [
      {
        path: '',
        component: SubPageComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@twaice-fe/frontend/features/incidents-detection').then(
                (m) => m['FrontendFeaturesIncidentsDetectionModule']
              ),
          },
        ],
      },
    ],
  },
  {
    path: 'performance-manager',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.ENERGY)],
    children: [
      {
        path: '',
        loadChildren: () => import('@twaice-fe/frontend/features/performance-manager').then((m) => m['PerformanceManagerModule']),
      },
    ],
  },
  {
    path: 'mobility',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.MOBILITY)],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@twaice-fe/frontend/features/fleet-prediction').then((m) => m['FleetPredictionFeatureModule']),
      },
    ],
  },
  {
    path: 'mobility-explore',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.MOBILITY)],
    children: [
      {
        path: '',
        loadChildren: () => import('@twaice-fe/frontend/features/mobility-explore').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'model-library',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.MODEL_LIBRARY)],
    children: [
      {
        path: '',
        redirectTo: 'library',
        pathMatch: 'full',
      },
      {
        path: 'library',
        children: [
          {
            path: '',
            loadChildren: () => import('@twaice-fe/frontend/features/model-library').then((m) => m['ModelLibraryModule']),
          },
        ],
      },
    ],
  },
  // generic analytics upsell page
  {
    path: 'analytics',
    canActivate: [hasSolution(Solution.MODEL_LIBRARY)],
    component: AnalyticsUpsellComponent,
  },
  // @deprecated since 09.03.24
  {
    path: 'fleet-analytics',
    component: SolutionComponent,
    canActivate: [hasSolution(Solution.MOBILITY)],
    children: [
      {
        path: '',
        redirectTo: 'soh-prediction',
        pathMatch: 'full',
      },
      {
        path: 'soh-prediction',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@twaice-fe/frontend/features/fleet-prediction').then((m) => m['FleetPredictionFeatureModule']),
          },
        ],
      },
    ],
  },
];
