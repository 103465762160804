<ng-container *ngIf="isNewTableDesignEnabled">
  <nz-table
    class="mb-64"
    id="systemOverviewTable"
    #systemOverviewTable
    nzShowPagination
    nzShowSizeChanger
    [nzFrontPagination]="true"
    [nzHideOnSinglePage]="true"
    nzPaginationType="small"
    nzSimple="true"
    [nzPageSize]="10"
    [nzData]="(systemData$ | async)!"
    [nzBordered]="false"
  >
    <thead>
      <tr class="custom-header">
        <th
          class="text-xs items-center py-0"
          *ngFor="let column of systemOverviewColumns"
          [nzSortOrder]="column.sortOrder"
          [nzSortFn]="column.sortFn"
          [nzSortDirections]="column.sortDirections"
          [nzWidth]="column.width"
        >
          <div class="flex items-center gap-4 text-xs font-semibold leading-lg" [ngClass]="column.class">
            {{ column.name }}

            <twaice-fe-popover-icon
              *ngIf="column.tooltip"
              [popoverContent]="columnPopoverContent"
              nzPopoverPlacement="top"
              customClasses="w-24 h-24"
            ></twaice-fe-popover-icon>

            <!-- popover start -->
            <ng-template #columnPopoverContent>
              <div class="max-w-[370px]">
                <h2 class="text-lg text-start font-semibold">{{ column.name }}</h2>

                <div class="mt-4 text-gray-600 text-justify">
                  <p>{{ column.tooltip.content }}</p>
                </div>

                <div class="mt-6" *ngIf="column.tooltip.linkText">
                  <a target="_blank" rel="noopener noreferrer" [href]="column.tooltip.link" class="text-blue-600 text-sm">{{
                    column.tooltip.linkText
                  }}</a>
                </div>
              </div>
            </ng-template>

            <!-- popover end -->
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of systemOverviewTable.data">
        <td class="align-top p-24">
          <div class="flex flex-col gap-8">
            <span class="text-xs font-semibold leading-[20px]">{{ data?.metadata.name }}</span>
            <span class="details-data text-xs text-secondary-text leading-[20px]">{{ data?.metadata.location }}</span>
            <div class="flex items-center gap-8 text-xs">
              <span class="details-data text-secondary-text pr-4 text-xs leading-[20px]">{{
                data?.metadata.nominalEnergyCapacity ?? '--'
              }}</span>
              <span class="border-l-1 h-16 w-1 p-4"></span>
              <span class="details-data text-secondary-text text-xs leading-[20px]">{{
                data?.metadata.maximumPower ?? '--'
              }}</span>
            </div>
          </div>
        </td>
        <td class="align-top p-24">
          <div class="flex flex-col justify-start gap-8 items-center">
            <ng-container *ngIf="data?.impact | async as energyLoss; else emptyImpact">
              <span class="text-xs text-center leading-[20px]"
                >{{ energyLoss?.lostMwh ? '-' + (energyLoss?.lostMwh | number: '1.0-2') : '--' }} MWh</span
              >
              <span class="text-xs text-center leading-[20px]">
                {{
                  energyLoss?.lostMoney
                    ? '-' + (energyLoss?.lostMoney | currency: energyLoss?.currency : 'symbol' : '1.0-0')
                    : ' --'
                }}
              </span>
            </ng-container>
            <ng-template #emptyImpact>
              <span class="text-xs leading-[20px]">-- MWh</span>
              <span class="text-xs leading-[20px]">--</span>
            </ng-template>
          </div>
        </td>
        <td (click)="navigate(data.performance?.link)" class="relative hover:shadow-2xl cursor-pointer align-top p-24">
          <div class="flex flex-col">
            <div class="flex flex-col items-center gap-8">
              <twaice-fe-svg-icon
                *ngIf="data.performance?.icon"
                [name]="data.performance?.icon"
                source="tabler"
                width="20px"
                height="20px"
                [color]="data.performance?.color"
              />
              <nz-badge class="-mr-8" *ngIf="!data.performance?.icon" [nzColor]="data.performance?.color"></nz-badge>
              <span class="text-secondary-text text-center text-xs leading-[20px] w-[150px]">
                <span *ngIf="data.performance?.value != null">{{ data.performance?.value }} %</span>
                {{ data.performance?.label }}</span
              >
            </div>
          </div>
        </td>
        <td (click)="navigate(data.safetyScore?.link)" class="relative hover:shadow-2xl cursor-pointer align-top p-24">
          <div class="flex flex-col">
            <div class="flex flex-col items-center gap-8">
              <twaice-fe-svg-icon
                *ngIf="data.safetyScore?.icon"
                [name]="data.safetyScore?.icon"
                source="tabler"
                width="20px"
                height="20px"
                [color]="data.safetyScore?.color"
              />
              <nz-badge class="-mr-8" *ngIf="!data.safetyScore?.icon" [nzColor]="data.safetyScore?.color"></nz-badge>
              <span class="text-secondary-text text-center text-xs leading-[20px] w-[150px]">
                <span *ngIf="data.safetyScore?.value != null">{{ data.safetyScore?.value }} %</span>
                {{ data.safetyScore?.label }}</span
              >
            </div>
          </div>
        </td>
        <td (click)="navigate(data.health?.link)" class="relative hover:shadow-2xl cursor-pointer align-top p-24">
          <div class="flex flex-col">
            <div class="flex flex-col items-center gap-8">
              <twaice-fe-svg-icon
                *ngIf="data.health?.icon"
                [name]="data.health?.icon"
                source="tabler"
                width="20px"
                height="20px"
                [color]="data.health?.color"
              />
              <nz-badge class="-mr-8" *ngIf="!data.health?.icon" [nzColor]="data.health?.color"></nz-badge>
              <span class="text-secondary-text text-center text-xs leading-[20px] w-[150px]">
                <span *ngIf="data.health?.value && data.health?.value != null">{{ data.health?.value }} %</span>
                {{ data.health?.label }}</span
              >
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</ng-container>

<twaice-fe-v2-datatable
  *ngIf="!isNewTableDesignEnabled"
  [config]="datatableConfig"
  [elements$]="dataSource$"
  [uniqueStateName]="tableFeatureKey"
  [mode]="customConfig?.overrideMode"
  [enableTableConfig]="customConfig?.showTableConfig"
  [cardEnabled]="true"
  bodyMaxHeight="max-h-[70vh]"
>
  <ng-template #listTemplate let-item let-columns="columns">
    <tr
      cell
      [class.selected]="item?.id === selectedSystemId"
      [content]="getContent(item)"
      [columns]="columns"
      (selected)="onRowSelected($event, item)"
      [attr.data-intercom-target]="intercomTarget + '-row-id-' + item?.id"
    ></tr>
  </ng-template>

  <ng-template #cardsTemplate let-item let-columns="columns">
    <twaice-fe-system-overview-table-card
      [config]="getContent(item)"
      [columns$]="columns"
      [selected]="item?.id === selectedSystemId"
      [isFleet]="isFleet"
      (click)="onRowSelected($event, item)"
      [attr.data-intercom-target]="intercomTarget + '-card-id-' + item?.id"
    />
  </ng-template>
</twaice-fe-v2-datatable>
