import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureKeyType, reducers, selectors } from '@twaice-fe/frontend/shared/store';

import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { EnergyLossDataInterface, FeatureFlagsEnum, OverviewSystem, Solution, System } from '@twaice-fe/shared/models';
import { insertIf, isEqual } from '@twaice-fe/shared/utilities';
import { distinctUntilChanged, filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { CellTypeEnum, DatatableConfigInterface, DatatableInterface, ListModeEnum } from '../datatable';
import { CellConfigInterface } from '../datatable/models/cell-config.interface';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { CellTagInterface } from '../datatable/models/cell-tag.interface';
import {
  ColumnItem,
  createTooltipText,
  formatSystemStats,
  getProgressBarOptions,
  healthValuesConfig,
  safetyScoreValuesConfig,
  statsValuesConfig,
  StatsValuesConfigInterface,
  SystemOverviewAntTableInterface,
} from './system-balancing-chart-config';

const { systemSelectors, configsSelectors, performanceManagerSelectors } = selectors;
const {
  systemReducer: { SYSTEMS_FEATURE_KEY },
} = reducers;

@Component({
  selector: 'twaice-fe-system-portfolio-overview-table',
  templateUrl: 'system-portfolio-overview-table.component.html',
  styleUrls: ['./system-portfolio-overview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
})
export class SystemPortfolioOverviewTableComponent implements OnInit, OnDestroy, DatatableInterface {
  @Output() rowSelected: EventEmitter<System> = new EventEmitter<System>();

  @Input() selectedSystemId?: string;
  @Input() customConfig?: {
    overrideMode?: ListModeEnum;
    showTableConfig?: boolean;
  };
  @Input() isFleet = false;
  @Input() intercomTarget?: string;

  dataSource$: Observable<OverviewSystem[]>;
  systemData$: Observable<SystemOverviewAntTableInterface[]>;
  destroy$: Subject<void> = new Subject();

  tableFeatureKey: FeatureKeyType = SYSTEMS_FEATURE_KEY;
  datatableConfig: DatatableConfigInterface = {};
  datatableContent: Record<string, DatatableConfigInterface> = {};

  isPerformanceManagerEnabled = false;
  isNewTableDesignEnabled = false;
  systemOverviewColumns: ColumnItem<OverviewSystem>[] = this.createSystemOverviewColumnItems();

  constructor(
    protected store: Store,
    private decimalPipe: DecimalPipe,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.dataSource$ = this.store.select(systemSelectors.getSystemListWithIncidents).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      tap(() => this.changeDetectorRef.detectChanges()),
      takeUntil(this.destroy$)
    );

    this.systemData$ = this.store.select(systemSelectors.getSystemList).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      tap(() => this.changeDetectorRef.detectChanges()),
      map((data) => this.formatSystemOverviewData(data)),
      takeUntil(this.destroy$)
    );

    this.store
      .select(configsSelectors.getAvailableSolutionList)
      .pipe(takeUntilDestroyed())
      .subscribe((solutions) => {
        this.isPerformanceManagerEnabled = solutions.includes(Solution.ENERGY);
      });
  }

  ngOnInit(): void {
    this.subscribeToFeatureFlags();
    this.initConfigListener();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigate(link: string) {
    this.router.navigateByUrl(link);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getContent(item?: OverviewSystem | Record<string, any>): Record<string, CellConfigInterface> {
    item = item || {};

    let config: { [key: string]: CellConfigInterface } = {
      storage: {
        header: 'Name',
        text: item?.name,
        section: {
          id: 1,
          title: 'Connected Storages',
          colspan: 5,
        },
      },
      location: {
        header: 'Location',
        text: item?.metadata?.location,
        sectionId: 1,
        color: 'rgba(4, 15, 38, 0.4)',
      },
      start: {
        header: 'Operational Since',
        text: new DatePipe('en-DE').transform(item?.metadata?.commercialOperationDate, 'MMM dd, yyyy') ?? '-',
        sectionId: 1,
        color: 'rgba(4, 15, 38, 0.4)',
      },
      nominalEnergyCapacity: {
        header: 'Initial Capacity',
        text: item?.metadata?.nominalEnergyCapacity,
        sectionId: 1,
        color: 'rgba(4, 15, 38, 0.4)',
      },
      maximumPower: {
        header: 'Max Power',
        text: item?.metadata?.maximumPower,
        sectionId: 1,
        color: 'rgba(4, 15, 38, 0.4)',
      },
      totalCycles: {
        header: 'Total',
        text: item?.kpis?.efcAvg,
        numberFormat: '1.0-0',
        section: {
          id: 2,
          title: 'Cycles',
          colspan: 2,
        },
      },
      cycleLast30Days: {
        header: 'Last 30 days',
        text: item?.kpis?.efcSum,
        numberFormat: '1.0-0',
        sectionId: 2,
      },
      minSoh: {
        header: 'Lowest',
        text: item?.kpis?.sohCMin,
        numberFormat: '1.0-0',
        measurand: '%',
        section: {
          id: 3,
          title: 'State of Health by strings',
          colspan: 3,
        },
      },
      maxSoh: {
        header: 'Highest',
        text: item?.kpis?.sohCMax,
        numberFormat: '1.0-0',
        measurand: '%',
        sectionId: 3,
      },
      spread: {
        header: 'Spread',
        text:
          item?.kpis?.sohCMax && item?.kpis?.sohCMin
            ? this.decimalPipe.transform(item?.kpis?.sohCMax - item?.kpis?.sohCMin, '1.0-1') + '%'
            : null,
        sectionId: 3,
      },
    };

    if (this.isPerformanceManagerEnabled) {
      if (!item?.systemStatistics?.x) {
        config = {
          ...config,
          balancing: {
            header: 'Balancing',
            section: {
              id: 4,
              title: 'Performance',
              colspan: 1,
            },
            type: CellTypeEnum.TEXT,
            text: '--',
          },
        };
      } else {
        config = {
          ...config,
          balancing: {
            header: 'Balancing',
            section: {
              id: 4,
              title: 'Performance',
              colspan: 1,
            },
            type: CellTypeEnum.PROGRESS_BAR,
          },
        };
        const systemStatistics = item.systemStatistics;
        const tooltipText = createTooltipText(this.datePipe, systemStatistics);
        const formattedSystemStatistics = formatSystemStats(systemStatistics.y);

        config.balancing = {
          ...config.balancing,
          text: tooltipText,
          link: `/performance-manager/balancing?systemID=${item.id}&kpi=${systemStatistics.kpi}&selectedDay=${systemStatistics.x}&severityFilter=all`,
          progressBarOptions: getProgressBarOptions(formattedSystemStatistics),
        };
      }
    }

    config = {
      ...config,
      incidents: {
        header: 'Active Incidents',
        type: CellTypeEnum.TAGS,
        customOptions: {
          emptyTagText: 'None',
        },
      },
    };

    if (item?.incidents) {
      config.incidents = {
        ...config.incidents,
        tags: this.getIncidentsTags(item),
      };
    }

    if (item?.id) {
      this.datatableContent[item?.id] = config;
    }

    return config;
  }

  onRowSelected($event: CellConfigInterface, item: System) {
    this.rowSelected.emit(item);
  }

  private initConfigListener() {
    this.datatableConfig = this.getContent();

    this.store
      .select(systemSelectors.selectSystemByRoute)
      .pipe(
        filter((system) => !!system),
        tap((system) => {
          this.selectedSystemId = system.id;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private subscribeToFeatureFlags(): void {
    this.store
      .select(configsSelectors.getConfigFeatureFlagList)
      .pipe(distinctUntilChanged(isEqual))
      .subscribe((featureFlags) => {
        if (this.isFleet) return;

        const listFeatureFlags = featureFlags as FeatureFlagsEnum[];
        this.isNewTableDesignEnabled =
          listFeatureFlags.includes(FeatureFlagsEnum.ENERGY_ASSET_DASHBOARD_SHOW_TRAFFIC_LIGHT) &&
          this.customConfig.overrideMode == null;
      });
  }

  private createSystemOverviewColumnItems(): ColumnItem<OverviewSystem>[] {
    return [
      {
        name: 'Storage',
        width: '223px',
      },
      {
        name: 'Daily Loss',
        class: 'justify-center',
        width: '150px',
        tooltip: {
          link: 'https://help.twaice.cloud/en/articles/206437-energy-loss-calculation-how-and-what',
          linkText: 'Learn more about the Daily Loss',
          content:
            'This metric tracks the loss in energy and revenue due to the difference between current available energy and initial capacity. Calculated based on one cycle per day, with no consideration of aging. Revenue loss is based on an market specific benchmark. Use this insight to optimize performance.',
        },
      },
      {
        name: 'Performance Status',
        tooltip: {
          content:
            'This metric indicates the percentage of nodes in a storage with balancing issues. The severity is based on the node with the highest imbalance. Use this insight to identify imbalances nodes and restore optimal performance',
        },
        class: 'justify-center',
      },
      {
        name: 'Safety Index',
        tooltip: {
          link: '',
          linkText: 'Learn more about the Safety Index',
          content:
            "The index reflects the current safety status of the storage, ranging from 'Normal’, over ‘Warning’ to 'Critical.' It is calculated based on the number and severity of safety incidents. Use this index to prioritize maintenance and reduce risks.",
        },
        class: 'justify-center',
      },
      {
        name: 'State of Health Spread',
        tooltip: {
          content:
            'This metrics reflects the range between the healthiest and least healthy strings in the storage. Use this insight to address strings with lower health to ensure overall system stability.',
        },
        class: 'justify-center',
      },
    ];
  }

  private getPerformanceStatus(system): StatsValuesConfigInterface {
    const formattedStats = formatSystemStats(system.systemStatistics?.y);
    const link = `/performance-manager?systemID=${system.id}&kpi=${system.systemStatistics?.kpi}`;

    const highSpreadCount = Number(this.decimalPipe.transform(formattedStats.high, '1.0-0'));
    const mediumSpreadCount = Number(this.decimalPipe.transform(formattedStats.medium, '1.0-0'));
    const balancedSpreadCount = Number(this.decimalPipe.transform(formattedStats.balanced, '1.0-0'));

    const performanceIndex = highSpreadCount + mediumSpreadCount;

    if (highSpreadCount === 0 && mediumSpreadCount === 0 && balancedSpreadCount === 0) {
      return { ...statsValuesConfig.missing, link };
    }
    if (highSpreadCount > 0) {
      return { ...statsValuesConfig.high, value: performanceIndex, link };
    }
    if (mediumSpreadCount > 0) {
      return { ...statsValuesConfig.medium, value: performanceIndex, link };
    }
    if (balancedSpreadCount > 0) {
      return { ...statsValuesConfig.balanced, value: null, link };
    }
  }

  private getHealthStatusBasedOnSohSpread(system): StatsValuesConfigInterface {
    const sohSpread = system?.kpis?.sohCMax && system?.kpis?.sohCMin ? system?.kpis?.sohCMax - system?.kpis?.sohCMin : null;

    const link = `/energy/health?systemID=${system.id}`;

    const THRESHOLD_BALANCED_SPREAD = 5;
    const THRESHOLD_MEDIUM_SPREAD = 10;

    if (!sohSpread) {
      return { ...healthValuesConfig.missing, link };
    }

    if (sohSpread < THRESHOLD_BALANCED_SPREAD) {
      return { ...healthValuesConfig.balanced, link };
    } else if (sohSpread < THRESHOLD_MEDIUM_SPREAD) {
      return { ...healthValuesConfig.medium, label: null, value: +this.decimalPipe.transform(sohSpread, '1.0-1'), link };
    } else {
      return { ...healthValuesConfig.high, label: null, value: +this.decimalPipe.transform(sohSpread, '1.0-1'), link };
    }
  }

  private getEnergyLossImpact(system): Observable<EnergyLossDataInterface> {
    return this.store.select(performanceManagerSelectors.getEnergyLossBySystem(system.rootContainerId));
  }

  private getSafetyScoreStatus(system): StatsValuesConfigInterface {
    const value = system.safetyScore?.score;
    const link = `/incidents?systemID=${system.id}`;

    if (!value) {
      return { ...safetyScoreValuesConfig.missing, link };
    }

    if (value < 90) {
      return { ...safetyScoreValuesConfig.high, link };
    } else if (value < 98) {
      return { ...safetyScoreValuesConfig.medium, link };
    } else {
      return { ...safetyScoreValuesConfig.balanced, link };
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getIncidentsTags(item?: OverviewSystem | Record<string, any>): CellTagInterface[] {
    return [
      ...insertIf(item?.incidents?.ongoingIncidents.low > 0, {
        text: item?.incidents?.ongoingIncidents.low,
        icon: 'icon_severity_minor',
        link: `/incidents?system_name=${item.name}&max_severity=low`,
      }),
      ...insertIf(item?.incidents?.ongoingIncidents.medium > 0, {
        text: item?.incidents?.ongoingIncidents.medium,
        icon: 'icon_severity_significant',
        link: `/incidents?system_name=${item.name}&max_severity=medium`,
      }),
      ...insertIf(item?.incidents?.ongoingIncidents.high > 0, {
        text: item?.incidents?.ongoingIncidents.high,
        icon: 'icon_severity_high',
        link: `/incidents?system_name=${item.name}&max_severity=high`,
      }),
      ...insertIf(item?.incidents?.ongoingIncidents.critical > 0, {
        text: item?.incidents?.ongoingIncidents.critical,
        icon: 'icon_severity_critical',
        link: `/incidents?system_name=${item.name}&max_severity=critical`,
      }),
    ];
  }

  private formatSystemOverviewData(data: OverviewSystem[]): SystemOverviewAntTableInterface[] {
    return data.map((system) => ({
      metadata: {
        id: system.id,
        name: system.name,
        location: system.metadata?.location,
        nominalEnergyCapacity: system.metadata?.nominalEnergyCapacity,
        maximumPower: system.metadata?.maximumPower,
        efcSum: system.kpis?.efcSum,
      },
      safetyScore: this.getSafetyScoreStatus(system),
      performance: this.getPerformanceStatus(system),
      health: this.getHealthStatusBasedOnSohSpread(system),
      impact: this.getEnergyLossImpact(system),
    }));
  }
}
